import React from 'react'
import { Hover, Icon } from '@Common/Components'

export const ButtonInfo = () => {
  const redirectToGuide = () => {
    const pathName = window.location.pathname
    if (pathName.includes('quotes')) {
      window.open('https://siap.atlassian.net/wiki/spaces/SAT/pages/36503577/Modulo+Preventivi')
      return null
    }
    if (pathName.includes('clients')) {
      window.open('https://siap.atlassian.net/wiki/spaces/SAT/pages/36634656/Modulo+Anagrafica')
      return null
    }
    if (pathName.includes('messages')) {
      window.open('https://siap.atlassian.net/wiki/spaces/SAT/pages/36700168/Modulo+Messaggi')
      return null
    }
    if (pathName.includes('tour')) {
      window.open('https://siap.atlassian.net/wiki/spaces/SAT/pages/36864012/Modulo+Viaggi')
      return null
    }
    if (pathName.includes('settings')) {
      window.open('https://siap.atlassian.net/wiki/spaces/SAT/pages/36470803/Impostazioni')
      return null
    }
    if (pathName.includes('offers')) {
      window.open('https://siap.atlassian.net/wiki/spaces/SAT/pages/36962311/Modulo+Offerte')
      return null
    }
    window.open('https://siap.atlassian.net/wiki/spaces/SAT/pages/37486593/Guida+APPTOUR')
  }

  return (
    <div
      style={{
        marginLeft: 20
      }}
    >
      <Hover>
        <Icon name='questions' size={26} onClick={redirectToGuide} />
      </Hover>
    </div>
  )
}
