import { FC, getHost, invalidateQuery, prefetchQueries } from '@Common/Services'
import React, { useState, useRef } from 'react'
import { Button, Card, Flex, Input, Spinner, Text } from '..'
import { useMount } from 'react-use'
import { Messages } from 'primereact/messages'
import state, { doLogout } from '@Common/State'
import { isNumber } from 'lodash'

export default function DataSelection ({ userInfo }) {
  const { activeCompanies } = userInfo
  const [societies, setSocieties] = useState([])
  const [opUnits, setOpUnits] = useState([])
  const [operators, setOperators] = useState([])
  const [loading, setLoading] = useState(false)
  const [userSelection, setUserSelection] = useState({ codSociety: '', opUnit: '', operator: '' })

  const setAuth = state.auth.useState()[1]

  const messages = useRef(null)

  const host = getHost()

  useMount(() => {
    const socCodes = activeCompanies.map(({ codSociety }) => codSociety)
    const currentSociety = { ...activeCompanies[0] }
    const uoCodes = [...currentSociety.opUnits]
    const operatorCodes = [...currentSociety.operators]
    setSocieties(socCodes)
    setOpUnits(uoCodes)
    setOperators(operatorCodes)
    setUserSelection({ ...userSelection, codSociety: socCodes[0] })
  })

  const handleChangeSociety = (e) => {
    const currentSociety = activeCompanies.find(({ codSociety }) => codSociety === e.codSociety)
    const uoCodes = [...currentSociety.opUnits]
    const operatorCodes = [...currentSociety.operators]
    setOpUnits(uoCodes)
    setOperators(operatorCodes)
    setUserSelection({ codSociety: e.codSociety, opUnit: '', operator: '' })
  }

  const getOperatorNameAndLastname = (operatorDesc) => {
    const atlName = operatorDesc.split(' ').slice(0, -1).join(' ')
    const atlLastName = operatorDesc.split(' ').slice(-1).join(' ')
    return { name: atlName, lastName: atlLastName }
  }

  const handleLogin = async () => {
    setLoading(true)
    const extractValue = (val) => val.split('-')[0].trim()
    const response = await FC.service('users').patch(userInfo.userId, { codSociety: extractValue(userSelection.codSociety), opUnit: (extractValue(userSelection.opUnit) || 'none'), usrAtl: extractValue(userSelection.operator), ...getOperatorNameAndLastname(userSelection.operator.split('-').pop()), atlanteId: userInfo?.atlanteId })
    if (!response) {
      messages?.current?.show({ severity: 'error', closable: false, summary: 'Impossibile effettuare l\'accesso.' })
      setLoading(false)
      return false
    }
    prefetchQueries()
    const { codSociety = '', opUnit = '', loginCount } = await FC.service('agencies').get(response.agency)

    const loginCountIncreased = loginCount && isNumber(loginCount) ? loginCount + 1 : 1
    FC.service('agencies').patch(response.agency, { loginCount: loginCountIncreased, loginLast: new Date() })

    setAuth({
      ...userInfo,
      userAgency: response.agency,
      codSociety,
      opUnit,
      usrAtl: extractValue(userSelection.operator),
      atlanteId: response.atlanteId
    })
    invalidateQuery(['agency', 'user'])
  }

  const handleLogout = () => doLogout()

  return (
    <Flex fw fh>
      <Card style={{ width: '600px', padding: 35 }}>
        <img src={host[0].logo} style={{ width: '100%' }} alt='logo' />
        <Messages
          style={{ margin: '10px' }}
          ref={messages}
        />
        <Flex row style={{ marginTop: 10 }}>
          <Text style={{ width: 200 }} bold value='Società' />
          <Input
            style={{ marginLeft: 10 }}
            dropdown
            id='codSociety'
            options={societies}
            value={userSelection.codSociety}
            onChange={handleChangeSociety}
          />
        </Flex>
        {
          opUnits.length
            ? (
              <Flex row style={{ marginTop: 10 }}>
                <Text style={{ width: 200 }} bold value='Unità operativa' />
                <Input
                  style={{ marginLeft: 10 }}
                  dropdown
                  id='opUnit'
                  options={opUnits}
                  value={userSelection.opUnit}
                  onChange={(e) => setUserSelection({ ...userSelection, opUnit: e.opUnit })}
                />
              </Flex>
              )
            : null
        }
        <Flex row style={{ marginTop: 10 }}>
          <Text style={{ width: 200 }} bold value='Operatore' />
          <Input
            style={{ marginLeft: 10 }}
            dropdown
            id='operator'
            options={operators}
            value={userSelection.operator}
            onChange={(e) => setUserSelection({ ...userSelection, operator: e.operator })}
          />
        </Flex>
        <Flex fw jb style={{ marginTop: 20 }} row>
          <Button label='logout' onClick={handleLogout} style={{ marginBottom: 10, marginTop: 10 }} icon='primeicon pi-power-off' rich />
          {
            loading
              ? <Flex style={{ marginTop: 10, marginBottom: 10 }}><Spinner /></Flex>
              : <Button label='login' onClick={handleLogin} style={{ marginBottom: 10, marginTop: 10 }} rich icon='symbol-1' />
          }
        </Flex>
      </Card>

    </Flex>
  )
}
