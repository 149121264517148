import React, { useState } from 'react'
import sha256 from 'js-sha256'

import { Input, Flex, Button, Text } from '..'

const passResetToken = '3c8AXqrj7u6y5tp' // Token for resetting the user's password

export default function ModalResetPwd ({ closeModal, setMessage }) {
  const [email, setEmail] = useState('')

  const onChangeEmail = ({ email }) => {
    setEmail(email)
  }

  const handleReset = async () => {
    if (!email) {
      window.alert('Not valid')
      return
    }
    const hash = sha256(passResetToken + email)
    await fetch(`https://api.apptour.travel/public/askReset?id=${email}&token=${hash}`)
    setMessage('Se l\'indirizzo indirizzo esiste, verrà inviata una mail!')
    closeModal()
  }

  return (
    <Flex>
      <Text color='white' value={'Inserisci qui l\'indirzzo email. Se trovato, verrà inviata una mail con le istruzione per effettuare il reset.'} />
      <Input
        id='email'
        placeholder='Indirizzo email'
        type='email'
        pattern='.+@.+\..+'
        value={email}
        onChange={onChangeEmail}
        style={{ width: '100%', marginTop: 20 }}
      />
      <Button style={{ marginTop: 20 }} label='Richiedi reset' onClick={handleReset} />
    </Flex>

  )
}
