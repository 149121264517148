import React from 'react'
import * as Sentry from '@sentry/react'
import { ErrorPage } from '@Common/Pages'
import { getReportDialog } from '@Common/Services/Sentry'

export const ErrorHandler = ({ children }) => (
  <Sentry.ErrorBoundary
    showDialog
    dialogOptions={{ ...getReportDialog() }}
    fallback={({ error, componentStack, resetError }) => (
      <ErrorPage
        error={error}
        showButtons
        errorDescription='ERRORE INASPETTATO!'
        errorDetails={'Una segnalazione é stata inviata ai nostri sviluppatori, ci scusiamo per l\'inconveniente.'}
        resetError={resetError}
      />
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
)
