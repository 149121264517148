import state from '../State'
import { useLocation } from 'react-router-dom'

const pathMap = [
  { path: '/quotes/list', label: 'PREVENTIVI' },
  { path: '/quotes/new', label: 'NUOVO PREVENTIVO' },
  { path: '/quotes/:id', label: 'PREVENTIVO' },
  { path: '/messages/:id', label: 'MESSAGGI' },
  { path: '/messages/chat', label: 'MESSAGGI' },
  { path: '/messages/broadcast', label: 'BROADCAST' },
  { path: '/messages/all', label: 'MESSAGGI' },
  { path: '/messages/new', label: 'NUOVO MESSAGGIO' },
  { path: '/clients/list', label: 'CLIENTI' },
  { path: '/clients/new', label: 'NUOVO CLIENTE' },
  { path: '/clients/edit', label: 'MODIFICA CLIENTE' },
  { path: '/clients/management', label: 'GESTIONE DUPLICATI' },
  { path: '/settings/', label: 'IMPOSTAZIONI' },
  { path: '/tours/list', label: 'VIAGGI' },
  { path: '/tours/new', label: 'NUOVO VIAGGIO' },
  { path: '/tours/:id', label: 'MODIFICA VIAGGIO' },
  { path: '/offers/list', label: 'OFFERTE' },
  { path: '/offers/new', label: 'NUOVA OFFERTA' },
  { path: '/offers/:id', label: 'MODIFICA OFFERTA' },
  { path: '/appointments/list', label: 'I MIEI APPUNTAMENTI' },
  { path: '/atlTour/list', label: 'PRATICHE ATLANTE' },
  { path: '/administration/agencies', label: 'AGENZIE' },
  { path: '/administration/newAgencies', label: 'NUOVE AGENZIE' },
  { path: '/administration/stats', label: 'STATISTICHE' },
  { path: '/', label: 'HOME' }
]

export const usePageTitle = () => {
  useLocation()
  if (window.location.pathname === '/') return 'HOME'

  const matchTitle = ({ path }) => path === window.location.pathname ||
    (path.includes(':id') && path.includes(window.location.pathname.split('/')[1]))

  return pathMap.find(matchTitle)?.label || 'HOME'
}

export const getMenuItems = (agency) => {
  const { networkAdmin, admin } = state.auth.state
  return [
    {
      isAppTour: true,
      label: 'Home',
      icon: 'icon icon-home',
      path: '/home'
    },
    {
      isAppTour: true,
      label: 'Appuntamenti',
      icon: 'icon icon-clock',
      path: '/appointments/list',
      disabled: !!networkAdmin || !!admin || agency.hideAppointments || agency.network === 'robintur',
      subActions: [
        {
          label: 'Lista Appuntamenti',
          icon: 'star',
          path: '/appointments/list'
        },
        {
          label: 'Nuovo Appuntamento',
          icon: 'add',
          newWindow: true,
          path: '/newAppointment/?agency=' + agency.agencyCode
        }
      ]
    },
    {
      isAppTour: true,
      label: 'Clienti',
      icon: 'icon icon-account',
      path: '/clients/list',
      disabled: !!networkAdmin || !!admin || agency.hideClients || agency.network === 'robintur',
      subActions: [
        {
          label: 'Lista Clienti',
          icon: 'account',
          path: '/clients/list'
        },
        {
          label: 'Nuovo Cliente',
          icon: 'add',
          path: '/clients/new'
        },
        ...((agency?.hasAtl && agency?.hasCrm && agency?.services?.app)
          ? ([{
              label: 'Gestione Clienti',
              icon: 'copy',
              path: '/clients/management'
            }])
          : [])
      ]
    },
    {
      isAppTour: true,
      label: 'Messaggi',
      icon: 'icon icon-chat',
      path: '/messages/chat',
      disabled: !agency.services?.chat || !!networkAdmin || !!admin,
      subActions: [
        {
          label: 'Chat',
          icon: 'chat',
          path: '/messages/chat',
          param: true
        },
        {
          label: 'Tabella Messaggi',
          icon: 'chat-history',
          path: '/messages/all'
        },
        {
          label: 'Nuovo Messaggio',
          icon: 'add',
          path: '/messages/new'
        },
        {
          label: 'Messaggi Broadcast',
          icon: 'mail',
          path: '/messages/broadcast'
        }]
    },
    {
      isAppTour: true,
      label: 'Offerte',
      icon: 'icon icon-star',
      path: '/offers/list',
      disabled: !agency.services?.offers || !!networkAdmin || !!admin,
      subActions: [
        {
          label: 'Lista Offerte',
          icon: 'star',
          path: '/offers/list'
        },
        {
          label: 'Nuova Offerta',
          icon: 'add',
          path: '/offers/new'
        }
      ]
    },
    {
      isAppTour: true,
      label: 'Viaggi',
      icon: 'icon icon-notes',
      path: '/tours/list',
      disabled: !agency.services?.app || !!networkAdmin || !!admin,
      subActions: [
        {
          label: 'Lista Viaggi',
          icon: 'paperwork-2',
          path: '/tours/list'
        },
        {
          label: 'Nuovo Viaggio',
          icon: 'add',
          path: '/tours/new'
        }
      ]
    },
    {
      label: agency.services && agency.services?.quotesPlus ? 'Preventivi' : 'Preventivi',
      icon: 'icon icon-estimate',
      path: '/quotes/list',
      disabled: !agency.services?.quotes || !!networkAdmin || !!admin,
      subActions: [
        {
          label: 'Lista Preventivi',
          icon: 'sheet',
          path: '/quotes/list'
        },
        {
          label: 'Nuova Richiesta',
          icon: 'add',
          path: '/quotes/new'
        }
      ]
    },
    {
      isAppTour: true,
      label: 'Pratiche Atlante',
      icon: 'icon icon-sincronize-siap',
      path: '/atlTour',
      disabled: !agency.services?.app || !agency.hasAtl || !!networkAdmin || !!admin
    },
    {
      label: 'Impostazioni',
      icon: 'icon icon-settings',
      path: '/settings/'
    },
    {
      label: 'Amministrazione',
      icon: 'icon icon-tool',
      disabled: !admin && !networkAdmin,
      path: '/administration/agencies',
      subActions: [
        {
          label: 'Agenzie',
          icon: 'agencies',
          path: '/administration/agencies'
        },
        {
          label: 'Nuova Agenzia',
          icon: 'add',
          path: '/administration/newAgencies'
        },
        {
          label: 'Statistiche',
          icon: 'statistics',
          path: '/administration/stats'
        }
      ]
    },
    {
      label: 'Logout',
      icon: 'primeicon pi-power-off',
      path: 'logout'
    }
  ]
}
