import React from 'react'
import { Flex, Text } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

export const Privacy = () => {
  return (
    <>
      <Flex js id='wrapper' style={styles.wrapperStyle}>
        <Flex style={styles.contentContainerStyle}>
          <Flex fw style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
            <div style={styles.heroBannerStyle} />
            <Text size={38} value='INFORMATIVA AGLI UTENTI DEL SITO' style={{ fontFamily: 'Arial', position: 'absolute', color: '#fff', marginBottom: 20 }} />
            <Text size={16} value='ai sensi dell’articolo 13 del Reg. UE 2016/679 GDPR Dati raccolti presso l’interessato' style={{ fontFamily: 'Arial', position: 'absolute', color: '#fff', marginTop: 70 }} />
          </Flex>
          <Flex fw>
            <Flex fw row jb id='text-start' style={{ margin: 'auto', maxWidth: '80%', marginTop: 30 }}>
              <div>
                <img
                  src='https://s3.eu-central-1.amazonaws.com/siap.apptour.uploads/67510f54c699871e69a2cda81558bdfcd413f53b9cd455826551f3d2079255b2.jpeg'
                  style={{ width: 200 }}
                />
              </div>
              <Flex ae id='company-info'>
                <Text value='SIAP SRL' />
                <Text value='Via Sant’Albano, 13' />
                <Text value='12049 Trinità (CN)' />
                <Text value='Partita Iva: 01871320048' />
                <Text value='Codice Fiscale: 01871320048' />
              </Flex>
            </Flex>
            <div style={{ height: 1, width: '90%', backgroundColor: '#ccc', margin: '20px 0' }} />
            <Flex as style={{ maxWidth: '80%', marginBottom: 50, marginTop: 10 }}>
              <Text value='Gentile interessato,' />
              <Text style={styles.textSpaceTop} value='con la presente desideriamo informarLa sui trattamenti che andremo ad eseguire sui Suoi dati personali, nonché ogni informazione utile a facilitare il contatto e l’iterazione con i nostri uffici.' />
              <div style={{ marginTop: 20, marginBottom: 10 }}>
                <Text style={{ fontStyle: 'italic' }} bold value='Titolare del trattamento' />
                <Text value='Il titolare del trattamento è SIAP SRL. I dati di contatto sono i seguenti:' />
                <Text value='Telefono: +39 0172 652511 Mail: siapcn@siapcn.it Fax: +39 0172 652519' />
                <Text value='Sito Web: http://www.siapcn.it/ ' />
              </div>
              <div style={styles.textSpacing}>
                <Text style={{ fontStyle: 'italic' }} bold value='Trattamento dei dati personali' />
                <Text value="In questa pagina si descrivono le modalità di gestione del sito in riferimento al trattamento dei dati personali degli utenti che interagiscono con i servizi web sul presente sito di SIAP SRL. L'informativa è resa solo per il presente sito e non anche per altri siti web eventualmente consultati dall'utente tramite link." />
                <Text style={styles.textSpaceTop} value='Ai sensi dell’art. 13 del Reg. UE 2016/679 (GDPR), la scrivente, in qualità di Titolare del trattamento, La informa che i dati personali acquisiti saranno oggetto di trattamento nel rispetto dei diritti delle libertà fondamentali, nonché della dignità dell’interessato, con particolare riferimento alla riservatezza ed all’identità personale ed al diritto alla protezione dei dati personali.' />
              </div>
              <Text value='Ai sensi dell’art. 13 del citato Regolamento, pertanto, Le forniamo le seguenti informazioni:' />
              <div style={styles.textSpacing}>
                <Text style={{ fontStyle: 'italic' }} bold value='Dati di navigazione' />
                <Text value="I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet." />
                <Text style={styles.textSpaceTop} value='Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value="In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente." />
                <Text style={styles.textSpaceTop} value="Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l'elaborazione. I dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito." />
              </div>
              <div style={styles.textSpacing}>
                <Text style={{ fontStyle: 'italic' }} bold value="Dati forniti volontariamente dall'utente" />
                <Text value="L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell'indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva." />
              </div>
              <div style={styles.textSpacing}>
                <Text value='Specifiche informative di sintesi verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='I dati personali saranno trattati esclusivamente per finalità connesse e/o strumentali alla consultazione del sito, escluso, pertanto, ogni utilizzo diverso e/o confliggente con quelli dell’”Interessato”.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='Il trattamento delle informazioni che La riguardano sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della riservatezza e saranno trattati e conservati con strumenti informatici e con modalità cartacee per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='L’eventuale conferimento dei dati è facoltativo, tuttavia, il rifiuto a fornire i dati personali pertinenti allo scopo della raccolta non renderà possibile l’ottenimento di quanto richiesto.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='I trattamenti connessi ai servizi web di questo sito hanno luogo presso la sede di SIAP SRL e sono curati dai nostri incaricati del trattamento e dagli hosting provider.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='I Suoi dati personali non saranno in ogni caso soggetti a diffusione se non dietro specifico consenso.' />
              </div>
              <div style={styles.textSpacing}>
                <Text style={{ fontStyle: 'italic' }} bold value='Utilizzo dei cookies' />
                <Text value='Il sito Internet di SIAP SRL utilizza i cookies analiticamente riportati nella tabella seguente.' />
              </div>
              <DataTable
                style={{ marginTop: 20, marginBottom: 15 }} value={[
                  { name: 'TIPOLOGIA', google: 'COOKIE TECNICO' },
                  { name: 'ANONIMIZZAZIONE', google: 'SI' },
                  { name: 'PROPRIETARIO', google: 'Terza parte' },
                  { name: 'DESCRIZIONE', google: 'Cookies tecnici anonimizzati necessari per effettuare statistiche su accessi, pagine maggiormente consultate, browser più diffusi' },
                  { name: 'SCADENZA', google: '38 mesi' },
                  { name: 'LINK A INFORMATIVA PER COOKIES DI TERZE PARTI', google: 'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage' }
                ]}
              >
                <Column field='name' header='Nome Cookie' />
                <Column
                  field='google' header='Insieme di cookies analitici di Google' body={(row) => {
                    return (
                      row.google.includes('http')
                        ? (<a style={{ color: '#555' }} href={row.google} target='_blank' rel='noreferrer'>{row.google}</a>)
                        : <p>{row.google}</p>
                    )
                  }}
                />
              </DataTable>
              <DataTable
                style={{ marginTop: 15, marginBottom: 20 }} value={[
                  { name: 'TIPOLOGIA', wordpress: 'COOKIE TECNICO' },
                  { name: 'ANONIMIZZAZIONE', wordpress: 'SI' },
                  { name: 'PROPRIETARIO', wordpress: 'Terza parte' },
                  { name: 'DESCRIZIONE', wordpress: 'Cookies tecnici anonimizzati necessarie a raccogliere informazioni, in forma aggregata, sul numero degli utenti e su questi visitano il sito stesso.' },
                  { name: 'SCADENZA', wordpress: 'Nessuna scadenza' },
                  { name: 'LINK A INFORMATIVA PER COOKIES DI TERZE PARTI', wordpress: 'https://it.wordpress.org/plugins/flamingo/' }
                ]}
              >
                <Column field='name' header='Nome Cookie' />
                <Column
                  field='wordpress'
                  header='Insieme di cookies tecnici di wordpress'
                  body={(row) => {
                    return (
                      row.wordpress.includes('http')
                        ? (<a style={{ color: '#555' }} href={row.wordpress} target='_blank' rel='noreferrer'>{row.wordpress}</a>)
                        : <p>{row.wordpress}</p>
                    )
                  }}
                />
              </DataTable>
              <div style={styles.textSpacing}>
                <Text value=' I link eventualmente contenuti in alcune delle nostre pagine portano su siti terzi che potrebbero utilizzare cookies di varia natura; Vi invitiamo pertanto a prendere visione delle relative informative presenti su tali siti e ad esprimere i consensi richiesti.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='La gestione di ogni singolo cookie è affidata all’utente che, manualmente o tramite i settaggi automatici del proprio browser, può decidere quali cookies accettare, con possibilità di eliminare in qualsiasi momento alcuni o tutti i cookies presenti sulla propria postazione di navigazione.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='Per agevolare l’impostazione e la gestione dei settaggi relativi ai cookies, si riportano di seguito i principali browser ed il link alle istruzioni per le impostazioni dei cookies (il link riporta a pagine esterne al nostro sito, manutenute ed aggiornate direttamente dai relativi produttori del software di navigazione).' />
              </div>
              <DataTable
                style={{ marginTop: 20, marginBottom: 20 }} value={[
                  { browser: 'Internet Explorer', link: ' http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11' },
                  { browser: 'Google Chrome', link: 'https://support.google.com/accounts/answer/61416?hl=it' },
                  { browser: 'Apple Safari', link: 'http://support.apple.com/kb/HT1677?viewlocale=it_IT' },
                  { browser: 'Mozilla Firefox', link: 'https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie' }
                ]}
              >
                <Column field='browser' header='Browser' />
                <Column field='link' header='Link' body={(row => <a style={{ color: '#555' }} href={row.link}>{row.link}</a>)} />
              </DataTable>
              <div style={styles.textSpacing}>
                <Text value='Un ulteriore aiuto nella gestione dei cookies può derivare dall’utilizzo di servizi automatizzati tipo quelli offerti dal sito.' />
                <a style={{ color: '#555' }} href='http://www.youronlinechoices.com/it' target='_blank' rel='noreferrer'>http://www.youronlinechoices.com/it/</a>
              </div>
              <div style={styles.textSpacing}>
                <Text value='La informiamo inoltre, sinteticamente, che Lei ha diritto di chiedere informazioni sui Suoi dati personali che stiamo trattando, chiederne la rettifica, la cancellazione, opporsi al trattamento, chiedere la limitazione del trattamento e la portabilità dei dati secondo quanto previsto dagli artt. da 15 a 22 del Reg. UE 2016/679 GDPR (copia del testo degli articoli citati può essere richiesto ai recapiti sopra evidenziati).' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='In qualsiasi momento Le è riconosciuto il diritto di proporre reclamo ad una autorità di controllo territorialmente competente.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='Qualora il trattamento sia basato in tutto o in parte sul Suo consenso, ha diritto a revocarlo liberamente ed in qualsiasi momento attraverso i contatti ai recapiti sopra evidenziati. I trattamenti effettuati in pendenza del consenso prestato ed i relativi effetti giuridici rimarranno comunque validi anche dopo l’eventuale revoca del consenso.' />
              </div>
              <div style={styles.textSpacing}>
                <Text value='Per ogni eventuale chiarimento o comunicazione in merito al trattamento dei Suoi dati, non esiti a contattarci.' />
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

const styles = {
  textSpaceTop: {
    marginTop: 5
  },
  textSpacing: {
    margin: '10px 0'
  },
  arrowButtonWrapperStyle: {
    position: 'fixed',
    left: '16.8%',
    top: '11%',
    cursor: 'pointer',
    zIndex: 10
  },
  arrowButtonStyle: {
    width: 50,
    height: 50,
    borderRadius: 25
  },
  heroBannerStyle: {
    width: '100%',
    height: 250,
    objectFit: 'cover',
    top: 70,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: '#292C33'
  },
  wrapperStyle: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    overflow: 'auto',
    paddingBottom: 100
  },
  contentContainerStyle: {
    width: '60%',
    marginTop: 70,
    backgroundColor: '#fff',
    borderRadius: 10
  }

}
