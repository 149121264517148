import React from 'react'
import { Flex, Button } from '..'
import { useHandleNavigate } from '@Common/Services'

const TopMenuButtons = ({ menuItems }) => {
  const navigate = useHandleNavigate()
  const pathname = window.location.pathname || ''
  const menuItem = menuItems.find(i => i?.path?.length > 2 && pathname?.includes(i?.path?.split('/')[1]))
  const subActions = menuItem?.subActions?.map(i => ({ ...i, isAppTour: menuItem?.isAppTour || false })) || []
  const matchExists = subActions.find(({ path }) => path === pathname)

  const handleOpenPage = (path, newWindow, isAppTour) => {
    if (newWindow) {
      window.open(window.origin + path)
    } else {
      navigate(path)
    }
  }

  return (
    <Flex fw row>
      {subActions.map(({ icon, path, label, param, newWindow, isAppTour }, ind) => {
        const isSelected = matchExists ? (path === pathname) : param
        return (
          <Button
            inverted={isSelected}
            key={ind}
            icon={icon}
            tooltip={label}
            round
            style={{ height: 30, marginLeft: 10, marginRight: 10 }}
            onClick={() => handleOpenPage(path, newWindow, isAppTour)}
          />
        )
      }
      )}
    </Flex>
  )
}
export default TopMenuButtons
