import React, { useState, useEffect } from 'react'
import { Hover, Icon, FadeIn, Text, Card } from '..'
import { FC, useCQuery } from '@Common/Services'
import updateFile from '../../../changelog.json'

function NewUpdate () {
  const [visibleList, setVisibleList] = useState(false)
  const [badge, setBadge] = useState(0)

  const { data: user = {} } = useCQuery('user')

  const seeVisibleList = async () => {
    setVisibleList(!visibleList)
    await FC.service('users').patch(user._id, { lastUpdateRead: updateFile[0].ver })
    setBadge(0)
  }

  useEffect(() => {
    const callUser = () => {
      FC.service('users')
        .get(user._id)
        .then((res) => {
          if (res && res.lastUpdateRead) {
            const ind = updateFile.map((e) => e.ver).indexOf(res.lastUpdateRead)
            setBadge(ind > 0 ? ind : 0)
          } else {
            setBadge(updateFile.length)
          }
        })
    }
    callUser()
  }, [user])

  const renderSingleElement = (item, index) => (
    <div
      key={index}
      style={{
        borderBottom: '1px solid #32324e',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
      }}
    >
      <Text bold value={item.data} style={{ marginBottom: 10 }} />
      <ul>
        {item.changes.map((chg, index2) => (
          <li key={index2}>
            <Text bold={chg.important} value={chg.value} style={{ marginBottom: 5 }} />
          </li>
        ))}
      </ul>
    </div>
  )

  const renderList = () => (
    <FadeIn className='p-grid p-nogutter p-dir-col' style={{ marginTop: 14, position: 'absolute', left: 38 }}>
      <Card
        style={{
          width: 400,
          height: 500,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'none',
          backgroundColor: 'rgba(255, 255, 255, 0.9)'
        }}
      >
        <Text size={20} bold value='COFFEE BREAK: ultimi aggiornamenti' style={{ marginBottom: 20 }} />
        {updateFile.map((item, index) => renderSingleElement(item, index))}
      </Card>
    </FadeIn>
  )

  return (
    <div style={{ marginLeft: 20, marginTop: -2 }}>
      <Hover>
        <Icon badge={badge} name='coffee' size={26} onClick={() => seeVisibleList()} />
      </Hover>
      {visibleList ? renderList() : null}
    </div>
  )
}

export default NewUpdate
