import React from 'react'

import { Flex, Button, Text } from '../../Components'

import imgCSS from '@Common/Resources/Images/404OK.gif'
import bgSpace from '@Common/Resources/Images/bg-space-1920x1080.png'

export const ErrorPage = ({ errorDescription, errorDetails, showButtons, resetError }) => {
  return (
    <Flex style={Styles.background}>
      <Flex style={{ width: '80%' }}>
        <Text size={40} value={errorDescription} color='white' />
        <Text size={30} value={errorDetails} color='white' />
        {showButtons && (
          <Flex row jb width={500} style={{ paddingTop: 20 }}>
            <Button icon='back' label='Torna Indietro' onClick={resetError} />
            <Button icon='monitor' label='Ricarica Pagina' onClick={() => window.location.reload()} />
          </Flex>
        )}
      </Flex>
      <img src={imgCSS} alt='no img' />
    </Flex>
  )
}

const Styles = {
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    backgroundImage: `url(${bgSpace})`
  }
}
