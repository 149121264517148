import React, { useState, useRef } from 'react'
import { getHost, getUrlParams } from '@Common/Services'
import { Button, Flex, InputOld, Spinner, Card, Text } from '..'
import { Messages } from 'primereact/messages'

export default function ResetPassword () {
  const [passwordResetToken, id] = getUrlParams(['passwordResetToken', 'id'])
  const host = getHost()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const messages = useRef()

  const handleChangePassword = async () => {
    if (newPassword && confirmPassword && id && passwordResetToken) {
      if (newPassword !== confirmPassword) messages?.current.show({ severity: 'error', closable: false, summary: 'Le password non sono uguali' })
      else {
        try {
          setLoading(true)
          const response = await fetch(`https://api.apptour.travel/public/doReset/?id=${id}&token=${passwordResetToken}&password=${newPassword}`)
          const parsedResponse = await response.json()

          if (!response.ok) {
            setLoading(false)
            messages?.current.show({ severity: 'error', closable: false, summary: parsedResponse?.message })
            setNewPassword('')
            setConfirmPassword('')
            return
          }

          const { success } = parsedResponse
          if (success) {
            setLoading(false)
            messages?.current?.show({ severity: 'success', closable: false, summary: 'Reset della password avvenuto correttamente. Verrai reindirizzato alla pagina di login.' })
            setTimeout(() => {
              window.location = '/'
            }, 2000)
          }
        } catch (e) {
          setLoading(false)
          messages?.current.show({ severity: 'error', closable: false, summary: 'Qualcosa è andato storto durante il reset. Riprova.' })
          setNewPassword('')
          setConfirmPassword('')
        }
      }
    } else if (!newPassword || !confirmPassword) messages?.current?.show({ severity: 'error', closable: false, summary: 'Una password mancante' })
    else messages?.current.show({ severity: 'error', closable: false, summary: 'Un valore di sistema necessario al reset, mancante. Contattare il supporto' })
  }

  return (
    <Flex wrap fw>
      <Card
        style={{
          width: '600px',
          height: '100vh',
          padding: 50,
          borderRadius: 0
        }}
      // fadeIn
      >

        <img src={host[0].logo} style={{ width: '100%', marginTop: '30%' }} alt='logo' />
        <Messages
          style={{ margin: '10px' }}
          ref={messages}
        />
        <Flex>
          <Flex style={{ marginTop: 20, marginBottom: 20 }}>
            <Text value='Benvenuto nella pagina per effettuare il reset della password.' />
            <Text value='Inserisici e conferma la nuova password per effettuare il reset' />
          </Flex>
          <InputOld
            id='password'
            password
            placeholder='Inserisci la nuova password'
            value={newPassword}
            onChange={({ password }) => {
              setNewPassword(password)
            }}
            style={{ width: 400, margin: '10px', borderRadius: 20 }}
          />
          <InputOld
            id='repeatPassword'
            password
            placeholder='Conferma la nuova password'
            value={confirmPassword}
            onChange={({ password }) => {
              setConfirmPassword(password)
            }}
            style={{ borderRadius: 20, width: 400, margin: '10px' }}
          />
          {loading
            ? <Spinner />
            : (
              <Button
                label='Reset'
                disabled={loading}
                onClick={handleChangePassword}
                style={{ marginBottom: 10, marginTop: 10 }}
              />)}
        </Flex>
      </Card>
    </Flex>
  )
}
